<!--

	THIS COMPONENT NEED TO BE REMOVED!

	Should be removed as soon as we using the ngVisCommon library.
	This library also contains this component.

-->

<ng-container [ngSwitch]="mode">
	<mat-icon *ngSwitchCase="'ng'" [attr.size]="size" [color]="color"> {{ name }}</mat-icon>

	<mat-icon
		*ngSwitchCase="'ng-svg'"
		[attr.size]="size"
		[color]="color"
		[svgIcon]="name ?? ''"
	></mat-icon>
</ng-container>
