import { environment } from './environment';

export const App = {
	Name1: 'Developer',
	Name2: 'Order Portal',
	ZeissLogo: '/assets/images/app-logo/zeiss-logo.svg',
	VisionLogo: '/assets/images/app-logo/vision-logo.svg',
	AppEntered: new Date(),
	LocalStorage: {
		Prefix: `ATHENA${
			environment.appMeta.environment !== 'PROD' ? '_' + environment.appMeta.environment : ''
		}_`,
		Settings: 'SETTINGS',
		Logs: 'LOGS',
	},
	RouteAnimationDuration: 200,
};

// TODO: Need to be removed and/or updated with new API
export const ApiShared = {
	Paths: {
		Product: '/product',
		ShippingLocation: '/shippinglocation',
		WearerAgeRange: '/weareragerange',
		Countries: '/country',
		Order: '/order',
		BuiltPhases: '/built',
		BuiltPhaseCurrent: '/built/current',
	},
};

// TODO: Need to be removed and/or updated with new API
export const ApiVersion = {
	'v1.0': '/v1.0',
};

export const customIcons = [
	{
		Label: 'cust-glasses-left',
		Path: 'assets/icons/glasses-left.svg',
	},
	{
		Label: 'cust-glasses-left-active',
		Path: 'assets/icons/glasses-left-active.svg',
	},
	{
		Label: 'cust-glasses-right',
		Path: 'assets/icons/glasses-right.svg',
	},
	{
		Label: 'cust-glasses-right-active',
		Path: 'assets/icons/glasses-right-active.svg',
	},
	{
		Label: 'cust-lens-add',
		Path: 'assets/icons/lens-add.svg',
	},
	{
		Label: 'cust-lens-edit',
		Path: 'assets/icons/lens-edit.svg',
	},
	{
		Label: 'cust-lens-check',
		Path: 'assets/icons/lens-check.svg',
	},
];

export const supportedFileTypes = [
	'image/jpg',
	'image/jpeg',
	'image/png',
	'application/pdf',
	'image/heic',
	'image/gif',
	'image/heif',
];

export const tcLink = 'legal/terms_and_conditions_2020.pdf';

export const footerLink = 'https://www.zeiss.com/vision-care/us/eyeglass-lenses-from-zeiss.html';
