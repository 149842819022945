import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AuthService } from 'src/app/common/auth/auth.service';
import { BusyDirective } from 'src/app/directives/busy/busy.directive';

@Component({
	selector: 'vis-splash',
	standalone: true,
	imports: [CommonModule, BusyDirective],
	templateUrl: './splash.component.html',
	styleUrls: ['./splash.component.scss'],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SplashComponent {
	constructor(public auth: AuthService) {}

	signIn() {
		setTimeout(() => this.auth.SignIn());
	}
}
