import { AppColors } from 'src/app/common/app';

import { Component, Input, OnChanges, SimpleChange } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { NgIf } from '@angular/common';
import { ValidationErrorsCustom } from './validators';

@Component({
	standalone: true,
	selector: 'vis-form-error',
	styleUrls: ['./form-error.component.scss'],
	templateUrl: './form-error.component.html',
	imports: [NgIf],
})
export class FormErrorComponent implements OnChanges {
	@Input() errors?: ValidationErrors | ValidationErrorsCustom | null;
	appColors = AppColors;
	lastError?: string;

	constructor() {}

	ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
		const errors = changes['errors'].currentValue as ValidationErrorsCustom;
		if (changes['errors'].currentValue) {
			if (errors.required) {
				this.lastError = 'Required';
			} else if (errors.requiredTrim) {
				this.lastError = 'Not only whitespaces';
			} else if (errors.minlength) {
				this.lastError = `Equal or greater <b>${errors.minlength.requiredLength}</b> characters`;
			} else if (errors.maxlength) {
				this.lastError = `Equal or less <b>${errors.maxlength.requiredLength}</b> characters`;
			} else if (errors.min) {
				this.lastError = `At least <b>${errors.min.min}</b>`;
			} else if (errors.max) {
				this.lastError = `Not greater than <b>${errors.max.max}</b>`;
			} else if (errors.fixedLength) {
				this.lastError = `Exactly <b>${errors.fixedLength.fixedLength}</b> ${errors.fixedLength.type}`;
			} else if (errors.allowedRegExp) {
				this.lastError = errors.allowedRegExp.description;
			} else if (errors.forbiddenRegExp) {
				this.lastError = errors.forbiddenRegExp.description;
			} else if (errors.codeAlreadyClaimed) {
				this.lastError = `This code was already used.`;
			} else if (errors.apiError) {
				this.lastError = `Couldn't validate code.`;
			} else if (errors.allowedCharCodes) {
				this.lastError = `${errors.allowedCharCodes.description}${(
					errors.allowedCharCodes.forbiddenChars ?? []
				)
					.map((x) => String.fromCodePoint(x))
					.join('')}`;
			} else if (errors.maxLengthSpecialChars) {
				this.lastError = `Your message does not fit in the available space.`;
			} else {
				this.lastError = 'Invalid';
			}
		} else {
			this.lastError = undefined;
		}
	}
}
