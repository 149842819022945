import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@zeiss/zui';
import '@zeiss/zui-icons';
import {
	registerTheme,
	registerTypefaces,
	setAppTheme,
	themeZbdsBase,
	themeZbdsLight,
} from '@zeiss/zui-themes';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

registerTheme(themeZbdsBase);
registerTheme(themeZbdsLight);
setAppTheme('zbds-light');

registerTypefaces('/fonts');

if (environment.appMeta.environment === 'PRD') {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
