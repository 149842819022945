import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUploadReference, Order } from 'src/app/common/interfaces';
import { ImageInformation } from '../../common/app';
import { Product } from '../../modules/lens-parameter/public-api';

export interface OrderProvider {
	submitOrder(order: OrderRequest): Observable<Order>;

	getOrders(): Observable<Order[]>;

	getOrder(id: string): Observable<Order | undefined>;

	deleteOrder(id: string): Observable<Order>;

	getProducts(): Observable<Product[]>;

	updateOrder(
		id: string,
		order: OrderRequest,
		changed: { rx: boolean; image: boolean }
	): Observable<Order>;

	uploadImage(files: FileList): Observable<FileUploadReference[]>;

	getImageData(fileId: string): Observable<ImageInformation>;

	readonly isBusy$: Observable<boolean>;
}

export interface OrderRequest {
	account: OrderAccountRequest;
	product: OrderProductRequest;
	shippingAddress: OrderShippingAddressRequest;
	type: 'rxPassData' | 'manualData';
	/*
	 * @Deprecated No longer used
	 */
	voucherCode: string;
}

export interface OrderShippingAddressRequest {
	companyName?: string;
	lastName: string;
	name: string;
	streetHouseNumber: string;
	city: string;
	countryCode: string;
	zip: string;
	state?: string;
}

export interface OrderAccountRequest {
	companyName?: string;
	lastName: string;
	name: string;
	eMailAddress: string;
}

export interface OrderProductRequest {
	engraving: string;
	quantity: number;
	wearerAgeRangeId: number;
	right?: OrderProductManualRequest;
	left?: OrderProductManualRequest;
	rxPass?: OrderRxPassDataRequest;
	outOfRangeValuesSelected: boolean;
}

export interface OrderProductManualRequest {
	sphere?: number;
	cylinder?: number;
	axis?: number;
	addition?: number;
	prism?: boolean;
}

export interface OrderProductConfigurationRequest {
	id: number;
	name: string;
	productType: string;
}

export interface OrderRxPassDataRequest {
	countryCode: string;
	countryStateCode?: string;
	rxPassFiles: OrderRxPassFile[];
}

export interface OrderRxPassFile {
	rxPassFileId: string;
	index: number;
}

export const ORDER_SERVICE = new InjectionToken<OrderProvider>('ORDER_SERVICE');
