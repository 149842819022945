import { AppColors } from 'src/app/common/app';

import { CommonModule, NgSwitch, NgSwitchCase } from '@angular/common';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	SimpleChanges,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	standalone: true,
	selector: 'vis-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	imports: [MatIconModule, CommonModule, NgSwitch, NgSwitchCase],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IconComponent implements OnInit, OnChanges {
	@Input() name?: string;
	@Input() size: string = 's16';
	@Input() color?: AppColors;
	@Input() mode: 'zui' | 'ng' | 'ng-svg' = 'zui';

	private _icon?: any;
	private _initialChanges?: SimpleChanges;

	constructor(private _el: ElementRef, private _renderer: Renderer2) {}

	ngOnInit(): void {
		if (this.mode === 'zui' && this.name) {
			this._icon = this._renderer.createElement(this.name);
			this._renderer.appendChild(this._el.nativeElement, this._icon);
			this.ngOnChanges(this._initialChanges!);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this._icon) {
			this._initialChanges = changes;
		}

		if (this.mode !== 'zui' || !this._icon) {
			return;
		}

		if (changes['color']) {
			this._renderer.setAttribute(this._icon, 'color', changes['color'].currentValue);
		}
		if (changes['size']) {
			this._icon!.size = this.size;
		}
	}
}
