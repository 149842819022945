import { Directive, ElementRef, Input, Renderer2, RendererStyleFlags2 } from '@angular/core';

@Directive({
	selector: '[busy]',
	standalone: true,
})
export class BusyDirective {
	private _container: ElementRef<HTMLDivElement>;
	private _spinnerContainer: ElementRef<HTMLDivElement>;
	private _spinner: ElementRef<HTMLElement>;
	private _elLastPosition = '';

	constructor(private _el: ElementRef<HTMLElement>, private _renderer: Renderer2) {
		this._container = this._renderer.createElement('div');
		this._spinnerContainer = this._renderer.createElement('div');
		this._renderer.addClass(this._container, 'vis-overlay-blur');

		this._spinner = this._renderer.createElement('zui-progress-ring');
		this._renderer.setStyle(this._spinner, 'borderRadius', '50%');
		this._renderer.setProperty(this._spinner, 'size', 's16');
		this._renderer.setProperty(this._spinner, 'emphasis', 'highlight');

		this._renderer.appendChild(this._container, this._spinnerContainer);
		this._renderer.appendChild(this._spinnerContainer, this._spinner);
	}

	@Input() set busy(value: boolean) {
		if (value) {
			this._elLastPosition = this._el.nativeElement.style.position;
			this._renderer.setStyle(
				this._el.nativeElement,
				'position',
				'relative',
				RendererStyleFlags2.Important
			);
			this._renderer.setAttribute(this._el.nativeElement, 'disabled', 'true');
			this._renderer.appendChild(this._el.nativeElement, this._container);
		} else {
			this._el.nativeElement.style.position = this._elLastPosition;
			this._renderer.removeAttribute(this._el.nativeElement, 'disabled');
			this._renderer.removeChild(this._el.nativeElement, this._container);
		}
	}
}
