<vis-toast></vis-toast>

<ng-container *ngIf="auth.Authenthicated | async; else splash">
	<vis-headerbar></vis-headerbar>

	<router-outlet></router-outlet>
</ng-container>

<ng-template #splash>
	<vis-splash></vis-splash>
</ng-template>
