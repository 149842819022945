import * as dayjs from 'dayjs';

export const LogPre = '[LOGGER]';

export interface LogMessage {
	timestamp: dayjs.Dayjs;
	category: LogCat;
	level: LogLevel;
	message: string;
	session: string;
}

export enum LogCat {
	system = 0,
	auth,
	product,
	user,
	order,
	country,
	wearerAge,
	voucher,
	settings,
}

export enum LogLevel {
	error = 0,
	warning,
	success,
	info,
	debug,
}
