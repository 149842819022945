import { DatePipe, NgIf } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { App, AppColors } from 'src/app/common/app';
import { AuthService } from 'src/app/common/auth/auth.service';
import { SettingsService } from 'src/app/common/settings/settings.service';
import { BusyDirective } from 'src/app/directives/busy/busy.directive';
import { ShortenPipe } from 'src/app/pipes/shorten.pipe';
import { Build } from 'src/environments/build';
import { environment } from 'src/environments/environment';
import { IconComponent } from '../icon/icon.component';
import { ItemComponent } from '../item/item.component';

@Component({
	standalone: true,
	selector: 'vis-profile-dialog',
	templateUrl: './profile-dialog.component.html',
	styleUrls: ['./profile-dialog.component.scss'],
	imports: [ItemComponent, NgIf, ShortenPipe, DatePipe, BusyDirective, IconComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProfileDialogComponent {
	busy = false;
	appColors = AppColors;
	app = App;
	build = Build;
	envDisplay = environment.appMeta.environment === 'PROD' ? '' : environment.appMeta.environment;

	constructor(public auth: AuthService, public settings: SettingsService) {}

	signOut() {
		this.busy = true;
		localStorage.clear(); // Remove users locally stored data
		setTimeout(() => this.auth.SignOut(), 1000);
	}
}
