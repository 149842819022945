import { HttpErrorResponse } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { LogCat, LogLevel } from '../logger/logger';
import { LoggerService } from '../logger/logger.service';

export interface OngoingRequest {
	id: string;
	type: string;
}

export function logError(
	logger: LoggerService,
	error: HttpErrorResponse | string,
	category: LogCat,
	silent = false,
	critical = false
) {
	let msg = '';
	if (typeof error === 'string') {
		msg = error;
	} else if (error.error?.apiStatus) {
		msg = `${error.error.apiStatus}`;
	} else {
		msg = `<b>${error.status}</b>: ${error.statusText}`;
	}

	if (!silent) {
		logger.Log(msg, category, { level: LogLevel.error, showToast: true });
	}

	if (critical) {
		return true;
	}

	return false;
}

export function startRequest(type?: string) {
	const retVal = { id: uuidv4(), type: type ?? '' };
	return retVal;
}
