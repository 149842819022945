import { Injectable } from '@angular/core';
import {
	AppConfigurationClient,
	FeatureFlagValue,
	GetConfigurationSettingResponse,
} from '@azure/app-configuration';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeatureFlags, LogPre, convertEnvironmentToFeatureFlagLabel } from './feature-flag';
import { FeatureFlagProvider } from './feature-flag.interface';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService implements FeatureFlagProvider {
	private readonly _featureFlagPrefix = '.appconfig.featureflag/';
	private _flags = new BehaviorSubject<FeatureFlags | undefined>(undefined);

	constructor() {
		this._init();
		this.FeatureFlags.subscribe((x) => console.debug(LogPre, 'FeatureFlags updated', x));
	}

	private _init() {
		const client = new AppConfigurationClient(environment.azureAppConfig.connectionString);

		const flags: FeatureFlags = {
			DOP_Frontend_Allow_Order_Placement: {
				enabled: false,
				label: convertEnvironmentToFeatureFlagLabel(environment.appMeta.environment),
			},
			DOP_Frontend_Engraving_Allow_Emojis: {
				enabled: false,
				label: convertEnvironmentToFeatureFlagLabel(environment.appMeta.environment),
			},
		};

		const requests: Promise<GetConfigurationSettingResponse>[] = [];
		for (const key in flags) {
			if (Object.prototype.hasOwnProperty.call(flags, key)) {
				const req = client.getConfigurationSetting(
					{ key: this._featureFlagPrefix + key, label: (flags as any)[key].label },
					{
						requestOptions: {
							customHeaders: { 'Cache-Control': 'no-cache' },
						},
					}
				);
				requests.push(req);
			}
		}

		forkJoin(requests).subscribe(
			(flagSettings) => {
				for (const _key in flags) {
					const key = _key as keyof FeatureFlags;
					if (Object.prototype.hasOwnProperty.call(flags, key)) {
						const rawValue = flagSettings.find(
							(flagSetting) => flagSetting.key.replace(this._featureFlagPrefix, '') === key
						)?.value;
						let parsedValue: FeatureFlagValue = rawValue
							? JSON.parse(rawValue)
							: {
									enabled: false,
									label: convertEnvironmentToFeatureFlagLabel(environment.appMeta.environment),
							  };
						flags[key] = {
							enabled: parsedValue.enabled,
							description: parsedValue.description,
							label: convertEnvironmentToFeatureFlagLabel(environment.appMeta.environment),
						};
					}
				}
				this._flags.next(flags);
			},
			(err) => console.warn(LogPre, 'Could not get feature flags.')
		);
	}

	get FeatureFlags() {
		return this._flags;
	}
}
