import { ShippingAddress } from '../components/shipping-address-section/shipping-address.interface';
import { Product } from '../modules/lens-parameter/public-api';
import { OrderRequest } from '../services/order/order';
import { ImageInformation } from './app';
import {
	FileUploadReference,
	Lens,
	Order,
	OrderApiResult,
	OrderRxFileUploadApiResult,
	ProductApiResult,
	ShippingInformation,
} from './interfaces';

export function transformOrderApiResult(input: OrderApiResult): Order {
	const output: Order = {
		id: input.id,
		createdTimestamp: input.orderCreationUtcTimestamp,
		orderId: input.orderId,
		zeissId: input.account.zeissId,
		type: input.type,
		errors: input.product.errors?.map((error) => {
			return {
				run: error.run,
				errorReceivedUtcTimestamp: error.errorReceivedUtcTimestamp,
				errorDetails: error.errorDetails.map((errorDetail) => {
					return {
						errorCode: errorDetail.errorCode,
						reviewerComment: errorDetail.reviewerComment,
						errorCodeText: errorDetail.errorMessage,
					};
				}),
			};
		}),
		status: {
			id: input.status.id,
			description: input.status.description,
			displayName: input.status.displayName,
		},
		shippingInfo: {
			id: input.account.id,
			zeissId: input.account.zeissId,
			additionalAddressInformation: input.shippingAddress?.additionalAddressInformation || '' || '',
			city: input.shippingAddress?.city || '',
			companyName: input.shippingAddress?.companyName || '',
			countryCode: input.shippingAddress?.countryCode || '',
			firstName: input.shippingAddress?.name || '',
			lastName: input.shippingAddress?.lastName || '',
			mailAddress: input.account.eMailAddress,
			state: input.shippingAddress?.state || '',
			street: input.shippingAddress?.streetHouseNumber || '',
			zipCode: input.shippingAddress?.zip || '',
		},
		product: {
			wearerAgeId: input.product.wearerAgeRangeId,
			engraving: input.product.engraving || undefined,
			outOfRangeValuesSelected: input.product.outOfRangeValuesSelected,
		},
		voucherCode: input.voucherCode,
	};

	if (input.product.rxPass && input.type === 'rxPassData') {
		output.rxData = {
			files: [
				...input.product.rxPass.rxPassFiles.map((file) => {
					return {
						fileId: file.rxPassFileId,
						zeissId: input.account.zeissId,
						fileName: file.rxPassFileName,
					};
				}),
			],
		};
	}

	if (input.product.left && input.product.right) {
		output.product.rx = {
			left: {
				addition: input.product.left.addition,
				axis: input.product.left.axis,
				cylinder: input.product.left.cylinder,
				prism: input.product.left.prism,
				sphere: input.product.left.sphere,
			},
			right: {
				addition: input.product.right.addition,
				axis: input.product.right.axis,
				cylinder: input.product.right.cylinder,
				prism: input.product.right.prism,
				sphere: input.product.right.sphere,
			},
		};
	}
	if (input.product.vRxLeft && input.product.vRxRight) {
		output.product.vrx = {
			left: {
				axis: input.product.vRxLeft.axis,
				cylinder: input.product.vRxLeft.cylinder,
				sphere: input.product.vRxLeft.sphere,
			},
			right: {
				axis: input.product.vRxRight.axis,
				cylinder: input.product.vRxRight.cylinder,
				sphere: input.product.vRxRight.sphere,
			},
		};
	}

	return output;
}

export function transformCompStateToOrderRequest(
	state: {
		voucher: string;
		productConfig: {
			engraving: string;
			wearerAgeId?: number;
		};
		shipping?: ShippingAddress;
		imageInfo?: ImageInformation;
		manualRx?: {
			left: Lens;
			right: Lens;
		};
		hasOutOfRange: boolean;
		fileUploadReferences: FileUploadReference[];
	},
	mail: string,
	zeissId: number,
	manualRx: boolean = false
): OrderRequest {
	const order: OrderRequest = {
		account: {
			companyName: state.shipping?.company!,
			eMailAddress: mail,
			lastName: state.shipping?.lastName!,
			name: state.shipping?.firstName!,
		},
		shippingAddress: {
			city: state.shipping?.city!,
			countryCode: state.shipping?.country!,
			name: state.shipping?.firstName!,
			lastName: state.shipping?.lastName!,
			streetHouseNumber: state.shipping?.street!,
			zip: state.shipping?.zipCode!,
			companyName: state.shipping?.company,
			state: state.shipping?.state,
		},
		product: {
			engraving: state.productConfig.engraving!,
			quantity: 1,
			wearerAgeRangeId: state.productConfig.wearerAgeId!,
			outOfRangeValuesSelected: state.hasOutOfRange,
		},
		type: manualRx ? 'manualData' : 'rxPassData',
		voucherCode: state.voucher,
	};

	if (manualRx) {
		order.product.left = {
			...state.manualRx!.left,
		};
		order.product.right = {
			...state.manualRx!.right,
		};
	} else {
		order.product.rxPass = {
			countryCode: state.shipping?.country!,
			countryStateCode: state.shipping?.state,
			rxPassFiles: [
				...state.fileUploadReferences.map((ref, index) => ({
					index,
					rxPassFileId: ref.fileId,
				})),
			],
		};
	}

	return order;
}

export function transformShippingInformationToShippingAddress(
	info?: ShippingInformation
): ShippingAddress | undefined {
	if (!info) return undefined;
	const address: ShippingAddress = {
		city: info.city || '',
		country: info.countryCode || '',
		firstName: info.firstName || '',
		lastName: info.lastName || '',
		state: info.state || '',
		street: info.street || '',
		zipCode: info.zipCode || '',
		company: info.companyName || '',
		floorBuilding: '',
	};

	return address;
}

export function transformProductApiResponse(input: ProductApiResult): Product {
	return {
		id: input.id,
		name: input.name,
		sphere: {
			cylinderRanges: input.sphere.cylinderRanges,
			step: input.sphere.step,
		},
		addition: {
			max: input.addition.max,
			min: input.addition.min,
			step: input.addition.step,
		},
		cylinder: {
			max: input.cylinder.max,
			min: input.cylinder.min,
			step: input.cylinder.step,
		},
		axis: {
			max: input.axis.max,
			min: input.axis.min,
			step: input.axis.step,
		},
		productType: input.productType,
	};
}

export const transformFileUploadApiResponse = (
	input: OrderRxFileUploadApiResult
): FileUploadReference => {
	return {
		fileId: input.rxPassFileId,
		zeissId: input.zeissId,
		fileName: input.rxPassFileName,
	};
};
