export const endpoints = {
	order: {
		ocpApimSubscriptionKey: '3c00a8cab6124446a7d7e27c9106edba',
		base: 'https://api-developer-order-portal-dev.zeiss.com/public',
		health: '/api/healthextended',
		oder: '/api/dop/order',
		country: '/api/dop/country',
		usStates: '/api/dop/country/us/state',
		product: '/api/dop/product',
		wearerAge: '/api/dop/weareragerange',
		rxPass: '/api/dop/rxpass',
	},
};
