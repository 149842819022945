import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { VisTooltipComponent } from './tooltip.component';
import { VisTooltipDirective } from './tooltip.directive';

@NgModule({
	declarations: [VisTooltipDirective],
	imports: [OverlayModule, VisTooltipComponent],
	exports: [VisTooltipDirective],
})
export class VisTooltipModule {}
