import { InjectionToken } from '@angular/core';
import { FeatureFlagProvider } from './feature-flag.interface';

export const LogPre = '[FEATURE]';

export type FeatureFlags = {
	DOP_Frontend_Allow_Order_Placement: FeatureFlagStatus;
	DOP_Frontend_Engraving_Allow_Emojis: FeatureFlagStatus;
};

export type FeatureFlagStatus = {
	enabled: boolean;
	description?: string;
	label: 'Develop' | 'Test' | 'Stage' | 'Production';
};

export const FEATURE_FLAG_PROVIDER = new InjectionToken<FeatureFlagProvider>(
	'FEATURE_FLAG_PROVIDER'
);

export function convertEnvironmentToFeatureFlagLabel(
	environment: string
): 'Develop' | 'Test' | 'Stage' | 'Production' {
	switch (environment) {
		case 'TEST':
			return 'Test';
		case 'STAGE':
			return 'Stage';
		case 'PROD':
			return 'Production';
		case 'DEV':
		default:
			return 'Develop';
	}
}
