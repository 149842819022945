import { NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { ToastService } from './toast.service';

@Component({
	standalone: true,
	selector: 'vis-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	imports: [NgForOf, NgSwitchCase, NgSwitch, NgSwitchDefault, NgIf],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ToastComponent {
	constructor(public toast: ToastService) {}
}
