import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ResetService {
	public readonly reset$ = new Subject<void>();

	public triggerReset() {
		this.reset$.next();
	}
}
