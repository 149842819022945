import * as dayjs from 'dayjs';
import { BehaviorSubject, throwError } from 'rxjs';
import { ToastService } from 'src/app/common/toast/toast.service';
import { environment } from 'src/environments/environment';
import { App } from 'src/environments/shared';
import { v4 as uuidv4 } from 'uuid';

import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { LogCat, LogLevel, LogMessage, LogPre } from './logger';

@Injectable({
	providedIn: 'root',
})
export class LoggerService {
	private sessionUuid = uuidv4();

	private _path = App.LocalStorage.Prefix + App.LocalStorage.Logs;
	private _logs: LogMessage[] = [];

	Logs$: BehaviorSubject<LogMessage[]>;

	private _appInsights?: ApplicationInsights;

	constructor(private _toast: ToastService) {
		this.aiInit();

		try {
			const storage = JSON.parse(localStorage.getItem(this._path)!);
			if (Array.isArray(storage)) {
				this._logs = storage;
				console.debug(LogPre, `Logs read from local storage`, {
					logs: { ...this._logs },
				});
			} else {
				throwError(`Storage object is not an array: ${storage}`);
			}
		} catch (error) {
			console.error(LogPre, `Could not read logs from LocalStorage.`, error, {
				logs: { ...this._logs },
			});
			this._logs = [];
		}

		this.Logs$ = new BehaviorSubject<LogMessage[]>(this._logs);
	}

	aiInit() {
		if (!environment.appInsights.activated) {
			console.debug(LogPre, `Application Insights not activated.`);
			return;
		}
		if (!environment.appInsights.instrumentationKey) {
			console.debug(LogPre, `Application Insights instrumentation key not found.`);
			return;
		}

		console.debug(
			LogPre,
			`Application Insights started. Instrumentation Key: ${environment.appInsights.instrumentationKey}`
		);

		this._appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: environment.appInsights.instrumentationKey,
				enableAutoRouteTracking: true,
				autoTrackPageVisitTime: true,
			},
		});
		this._appInsights.loadAppInsights();
	}

	aiLogEvent(name: string, properties?: { [key: string]: any }) {
		if (!this._appInsights || !environment.appInsights.activated) {
			return;
		}

		this._appInsights.trackEvent({ name: name }, properties);
	}

	aiLogMetric(name: string, average: number, properties?: { [key: string]: any }) {
		if (!this._appInsights || !environment.appInsights.activated) {
			return;
		}

		this._appInsights.trackMetric({ name: name, average: average }, properties);
	}

	aiLogException(exception: Error, severityLevel?: number) {
		if (!this._appInsights || !environment.appInsights.activated) {
			return;
		}

		this._appInsights.trackException({ exception: exception, severityLevel: severityLevel });
	}

	aiLogTrace(message: string, properties?: { [key: string]: any }) {
		if (!this._appInsights || !environment.appInsights.activated) {
			return;
		}

		this._appInsights.trackTrace({ message: message }, properties);
	}

	Log(
		message: string,
		category: LogCat,
		options?: {
			level?: LogLevel;
			showToast?: boolean;
			aiData?: { [key: string]: any };
		}
	) {
		const log: LogMessage = {
			timestamp: dayjs(),
			category: category,
			level: options?.level ?? LogLevel.info,
			message: message,
			session: this.sessionUuid,
		};
		console.debug(`[${LogCat[log.category].toUpperCase()}] ${log.message}`, options?.aiData ?? '');

		this._logs.push(log);
		this.Logs$.next(this._logs);

		// Remove 20 eldest if exceeding 100 records
		if (this._logs.length > 100) {
			this._logs.splice(80);
			console.debug(LogPre, `Removed 20 eldest log records`);
		}

		try {
			localStorage.setItem(this._path, JSON.stringify(this._logs));
		} catch (error) {
			console.error(LogPre, 'Could not write logs', { error: { error } });
		}

		if (options?.showToast) {
			this._toast.Create(LogCat[category]?.toUpperCase(), message, options?.level);
		}

		if (log.level === LogLevel.error) {
			this.aiLogException({ name: LogCat[log.category], message: log.message });
		}

		this.aiLogEvent(LogCat[log.category], {
			timestamp: log.timestamp,
			category: LogCat[log.category],
			level: LogLevel[log.level],
			message: `[${LogCat[log.category].toUpperCase()}] ${log.message}`,
			session: log.session,
			...options?.aiData,
		});
	}

	Clear() {
		this._logs = [];
		this.Logs$.next(this._logs);

		try {
			localStorage.setItem(this._path, JSON.stringify(this._logs));
			console.debug(LogPre, `Logs cleared.`);
		} catch (error) {
			console.error(LogPre, 'Could not write logs', { error: { error } });
		}
	}
}
