<zui-headerbar>
	<!-- APP LOGO -->
	<div slot="icon" class="pointer">
		<zui-zeiss-logo size="s48" [visTooltip]="app.Name1 + ' ' + app.Name2"></zui-zeiss-logo>
	</div>

	<!-- APP NAME -->
	<zui-headerbar-product-name
		class="headerbar-link"
		slot="productName"
		[attr.product-name]="app.Name1"
		[attr.product-sub-name]="app.Name2"
		(click)="router.navigate(['/order'])"
	>
	</zui-headerbar-product-name>
	<!-- PRODUCT -->
	<zui-headerbar-icon-button
		emphasis="default"
		slot="iconButtons"
		size="l"
		visTooltip="Place a lens order"
		id="tut-config-link"
		[attr.ttitle]="'Place a lens order'"
		[routerLink]="['order']"
		#rlaProduct="routerLinkActive"
		routerLinkActive
		[routerLinkActiveOptions]="{ exact: true }"
		[visAttribute]="{
			emphasis: rlaProduct.isActive ? 'selected' : 'default'
		}"
	>
		<vis-icon size="l" slot="icon" name="cust-lens-add" mode="ng-svg"></vis-icon>
	</zui-headerbar-icon-button>

	<!-- ORDERS -->
	<zui-headerbar-icon-button
		emphasis="default"
		size="m"
		slot="iconButtons"
		#rlaOrders="routerLinkActive"
		routerLinkActive
		[visAttribute]="{ emphasis: rlaOrders.isActive ? 'selected' : 'default' }"
		visTooltip="Your orders"
		[attr.badgecolor]="appColors.info"
		id="tut-orders-link"
		[attr.ttitle]="'Your orders'"
		[attr.ttext]="'Click here to inspect your orders.'"
		size="l"
		[routerLink]="['overview']"
		[visAttribute]="{ emphasis: rlaOrders.isActive ? 'selected' : 'default' }"
	>
		<vis-icon size="l" slot="icon" name="cust-lens-check" mode="ng-svg"></vis-icon>
	</zui-headerbar-icon-button>

	<!-- TUTORIAL -->
	<!-- <zui-headerbar-icon-button emphasis="default" size="m" slot="iconButtons" visTooltip="Help function"
		id="tut-help-link" [attr.ttitle]="'Help'" [attr.ttext]="
			'Click there to show up the different tutorial topics like the one you are currently seeing.'
		">
		<vis-icon size="m" slot="icon" name="zui-icon-about"></vis-icon>
	</zui-headerbar-icon-button> -->

	<!-- USER PROFILE -->
	<zui-user-menu-button
		*ngIf="auth.UserZeissId"
		size="m"
		slot="userMenuButton"
		visTooltip="User settings"
		id="tut-userprofile-link"
		[attr.ttitle]="'User profile'"
		[attr.ttext]="'Click here if you want to sign out or review your user profile.'"
		[attr.user-name]="
			auth.UserZeissId.ZeissIdBase.firstName + ' ' + auth.UserZeissId.ZeissIdBase.lastName
		"
		[attr.user-role]="auth.UserZeissId.ZeissIdOrganisation.name | shorten: 21"
		(click)="openUserProfile()"
	>
		<zui-avatar
			[attr.initials]="
				auth.UserZeissId.ZeissIdBase.firstName[0] + auth.UserZeissId.ZeissIdBase.lastName[0]
			"
			slot="avatar"
		></zui-avatar>
	</zui-user-menu-button>
</zui-headerbar>
