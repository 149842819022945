import { AsyncPipe, NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { App, AppColors } from 'src/app/common/app';
import { AuthService } from 'src/app/common/auth/auth.service';
import { VisTooltipModule } from 'src/app/common/tooltip/tooltip.module';
import { ShortenPipe } from 'src/app/pipes/shorten.pipe';
import { VisAttributeDirective } from '../../directives/attribute/attribute.directive';
import { ORDER_SERVICE } from '../../services/order/order';
import { OrderService } from '../../services/order/order.service';
import { IconComponent } from '../icon/icon.component';
import { ProfileDialogComponent } from '../profile-dialog/profile-dialog.component';

@Component({
	standalone: true,
	selector: 'vis-headerbar',
	templateUrl: './headerbar.component.html',
	styleUrls: ['./headerbar.component.scss'],
	imports: [
		IconComponent,
		NgIf,
		ShortenPipe,
		VisTooltipModule,
		MatDialogModule,
		ProfileDialogComponent,
		RouterModule,
		AsyncPipe,
		VisAttributeDirective,
	],
	providers: [
		{
			provide: ORDER_SERVICE,
			useClass: OrderService,
		},
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderbarComponent {
	app = App;
	appColors = AppColors;

	constructor(public auth: AuthService, private dialog: MatDialog, public router: Router) {}

	async openUserProfile() {
		await lastValueFrom(
			this.dialog
				.open(ProfileDialogComponent, {
					autoFocus: true,
					position: {
						top: '50px',
						right: '10vw',
					},
				})
				.afterClosed()
		);
	}
}
