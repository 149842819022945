import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { App } from 'src/environments/shared';

const APP_NAME_PREFIX = `${App.Name1} ${App.Name2} - `;

export const ROUTE_CONFIG = {
	order: {
		path: 'order',
		data: {
			animationHierarchy: 1,
			title: 'Order Lenses',
			icon: 'zui-icon-edf-edf-active',
		},
	},
	overview: {
		path: 'overview',
		data: {
			animationHierarchy: 2,
			title: 'Your Orders',
			icon: 'zui-icon-focus',
		},
	},
};

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: ROUTE_CONFIG.order.path,
	},
	{
		path: ROUTE_CONFIG.order.path,
		data: ROUTE_CONFIG.order.data,
		title: APP_NAME_PREFIX + ROUTE_CONFIG.order.data.title,
		loadComponent: () =>
			import('./pages/order-placement/order-placement.component').then(
				(m) => m.OrderPlacementComponent
			),
	},
	{
		path: ROUTE_CONFIG.overview.path,
		data: ROUTE_CONFIG.overview.data,
		title: APP_NAME_PREFIX + ROUTE_CONFIG.overview.data.title,
		loadComponent: () =>
			import('./pages/overview/overview.component').then((m) => m.OverviewComponent),
	},
	{
		path: '**',
		redirectTo: ROUTE_CONFIG.order.path,
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			enableTracing: environment.appMeta.environment === 'DEV' ? true : false,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
