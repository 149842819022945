import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { customIcons } from '../environments/shared';
import { AuthService } from './common/auth/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'DOP';

	constructor(
		public auth: AuthService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) {
		this.registerCustomIcons();
	}

	registerCustomIcons() {
		customIcons.forEach((icon: { Label: string; Path: string }) => {
			this.matIconRegistry.addSvgIcon(
				icon.Label,
				this.domSanitizer.bypassSecurityTrustResourceUrl(icon.Path)
			);
		});
	}
}
