// prettier-ignore
 // this file is automatically generated by git.version.ts script
 export const Build = {
   appVersion: `0.0.0`,
   branch: `MAIN`,
   lastCommit: `3f177f8`,
   lastCommitUrl: `https://dev.azure.com/zathena/Digital-Lens-Portal/_git/Frontend.DigitalLensPortal/commit/3f177f801a53e6a03fc19069bebdc84398949d2a`,
   lastCommitHash: `3f177f801a53e6a03fc19069bebdc84398949d2a`,
   lastCommitTime: `2024-06-13T12:08:34Z`,
   lastCommitMessage: `Merged PR 989: Chore: changed transform function

# Description

Model change on backend API. Need to adjust the frontend to reflect the new changes.

# Changelog
chore: changed transform function

# Refs
refs: #1086 #1085

Related work items: #1085, #1086`,
   lastCommitAuthor: `Aaron Czichon`
 };
 