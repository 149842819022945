import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './common/auth/auth.service';
import { FEATURE_FLAG_PROVIDER } from './common/feature-flag/feature-flag';
import { FeatureFlagService } from './common/feature-flag/feature-flag.service';
import { ResetService } from './common/reset/reset.service';
import { SettingsService } from './common/settings/settings.service';
import { ToastComponent } from './common/toast/toast.component';
import { ToastService } from './common/toast/toast.service';
import { HeaderbarComponent } from './components/headerbar/headerbar.component';
import { SplashComponent } from './components/splash/splash.component';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		ToastComponent,
		OAuthModule.forRoot(),
		HttpClientModule,
		HeaderbarComponent,
		SplashComponent,
	],
	providers: [
		ToastService,
		AuthService,
		SettingsService,
		ResetService,
		{
			provide: FEATURE_FLAG_PROVIDER,
			useClass: FeatureFlagService,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
