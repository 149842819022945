import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
	FileUploadReference,
	Order,
	OrderApiResult,
	OrderRxFileUploadApiResult,
	OrderRxPassImageApiResult,
	ProductApiResult,
} from 'src/app/common/interfaces';
import { LogCat } from 'src/app/common/logger/logger';
import { LoggerService } from 'src/app/common/logger/logger.service';
import { logError, OngoingRequest, startRequest } from 'src/app/common/services/service.common';
import {
	transformFileUploadApiResponse,
	transformOrderApiResult,
	transformProductApiResponse,
} from 'src/app/common/transformers';
import { endpoints } from 'src/environments/api-endpoints/endpoints';
import { ImageInformation } from '../../common/app';
import { Product } from '../../modules/lens-parameter/public-api';
import { OrderProvider, OrderRequest } from './order';

@Injectable()
export class OrderService implements OrderProvider {
	private ongoingRequests$: BehaviorSubject<OngoingRequest[]> = new BehaviorSubject<
		OngoingRequest[]
	>([]);
	private backendFailure$ = new BehaviorSubject<boolean>(false);

	private uploadProgress$ = new BehaviorSubject<number>(0);

	constructor(private http: HttpClient, private logger: LoggerService) {}

	public get isBusy$(): Observable<boolean> {
		return this.ongoingRequests$.pipe(map((x: any[]) => x.length > 0));
	}

	public get uploadProgress(): Observable<number> {
		return this.uploadProgress$.asObservable();
	}

	submitOrder(order: OrderRequest): Observable<Order> {
		const val = startRequest('submitOrder');
		const ongoingRequests = this.ongoingRequests$.getValue();
		ongoingRequests.push(val);
		this.ongoingRequests$.next(ongoingRequests);

		return this.http
			.post<OrderApiResult>(`${endpoints.order.base}${endpoints.order.oder}`, order, {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Ocp-Apim-Subscription-Key': endpoints.order.ocpApimSubscriptionKey,
					Authorization: `Bearer ${localStorage.getItem('id-token-zid')}`,
				},
			})
			.pipe(
				map((response) => transformOrderApiResult(response)),
				tap({
					next: (response) => {
						return response;
					},
					error: (err) => {
						const backendError = logError(this.logger, err, LogCat.order, false, true);
						this.backendFailure$.next(backendError);
					},
				}),
				finalize(() => this.endRequest(val.id))
			);
	}

	updateOrder(
		id: string,
		order: OrderRequest,
		changed: { image: boolean; rx: boolean }
	): Observable<Order> {
		const val = startRequest('updateOrder');
		const ongoingRequests = this.ongoingRequests$.getValue();
		ongoingRequests.push(val);
		this.ongoingRequests$.next(ongoingRequests);

		let query = '';
		if (order.type === 'manualData' && changed.rx) {
			query = '?rxr=1';
		} else if (order.type === 'rxPassData' && changed.image) {
			query = '?rxr=1&ic=1';
		}

		return this.http
			.put<OrderApiResult>(`${endpoints.order.base}${endpoints.order.oder}/${id}${query}`, order, {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Ocp-Apim-Subscription-Key': endpoints.order.ocpApimSubscriptionKey,
					Authorization: `Bearer ${localStorage.getItem('id-token-zid')}`,
				},
			})
			.pipe(
				map((response) => transformOrderApiResult(response)),
				tap({
					next: (response) => {
						return response;
					},
					error: (err) => {
						const backendError = logError(this.logger, err, LogCat.order, false, true);
						this.backendFailure$.next(backendError);
					},
				}),
				finalize(() => this.endRequest(val.id))
			);
	}

	getOrders(): Observable<Order[]> {
		const val = startRequest('getOrders');
		const ongoingRequests = this.ongoingRequests$.getValue();
		ongoingRequests.push(val);
		this.ongoingRequests$.next(ongoingRequests);

		return this.http
			.get<OrderApiResult[]>(`${endpoints.order.base}${endpoints.order.oder}`, {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Ocp-Apim-Subscription-Key': endpoints.order.ocpApimSubscriptionKey,
					Authorization: `Bearer ${localStorage.getItem('id-token-zid')}`,
				},
			})
			.pipe(
				map((response: OrderApiResult[]) => {
					return response.map((res) => transformOrderApiResult(res));
				}),
				tap({
					next: (response) => {
						return response;
					},
					error: (err) => {
						const backendError = logError(
							this.logger,
							'API Error: Cannot get orders',
							LogCat.order,
							false,
							true
						);
						this.backendFailure$.next(backendError);
					},
				}),
				finalize(() => this.endRequest(val.id))
			);
	}

	getOrder(id: string): Observable<Order | undefined> {
		const val = startRequest('getOrder');
		const ongoingRequests = this.ongoingRequests$.getValue();
		ongoingRequests.push(val);
		this.ongoingRequests$.next(ongoingRequests);

		return this.http
			.get<OrderApiResult | undefined>(`${endpoints.order.base}${endpoints.order.oder}/${id}`, {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Ocp-Apim-Subscription-Key': endpoints.order.ocpApimSubscriptionKey,
					Authorization: `Bearer ${localStorage.getItem('id-token-zid')}`,
				},
			})
			.pipe(
				map((response: OrderApiResult | undefined) => {
					if (!response) return undefined;
					return transformOrderApiResult(response);
				}),
				tap({
					next: (response) => {
						return response;
					},
					error: (err) => {
						const backendError = logError(
							this.logger,
							'API Error: Cannot get order',
							LogCat.order,
							false,
							true
						);
						this.backendFailure$.next(backendError);
					},
				}),
				finalize(() => this.endRequest(val.id))
			);
	}
	deleteOrder(id: string): Observable<Order> {
		const val = startRequest('deleteOrder');
		const ongoingRequests = this.ongoingRequests$.getValue();
		ongoingRequests.push(val);
		this.ongoingRequests$.next(ongoingRequests);

		return this.http
			.delete<any>(`${endpoints.order.base}${endpoints.order.oder}/${id}`, {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Ocp-Apim-Subscription-Key': endpoints.order.ocpApimSubscriptionKey,
					Authorization: `Bearer ${localStorage.getItem('id-token-zid')}`,
				},
			})
			.pipe(
				tap({
					next: () => {},
					error: (err) => {
						const backendError = logError(
							this.logger,
							'API Error: Cannot delete order',
							LogCat.order,
							false,
							true
						);
						this.backendFailure$.next(backendError);
					},
				}),
				finalize(() => this.endRequest(val.id))
			);
	}

	getProducts(): Observable<Product[]> {
		const val = startRequest('getProducts');
		const ongoingRequests = this.ongoingRequests$.getValue();
		ongoingRequests.push(val);
		this.ongoingRequests$.next(ongoingRequests);

		return this.http
			.get<ProductApiResult[]>(`${endpoints.order.base}${endpoints.order.product}`, {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Ocp-Apim-Subscription-Key': endpoints.order.ocpApimSubscriptionKey,
					Authorization: `Bearer ${localStorage.getItem('id-token-zid')}`,
				},
			})
			.pipe(
				map((response) => response.map((res) => transformProductApiResponse(res))),
				tap({
					next: (response) => {
						return response;
					},
					error: (err) => {
						const backendError = logError(
							this.logger,
							'API Error: Cannot get products',
							LogCat.order,
							false,
							true
						);
						this.backendFailure$.next(backendError);
					},
				}),
				finalize(() => this.endRequest(val.id))
			);
	}

	uploadImage(files: FileList): Observable<FileUploadReference[]> {
		const val = startRequest('uploadImage');
		const ongoingRequests = this.ongoingRequests$.getValue();
		ongoingRequests.push(val);
		this.ongoingRequests$.next(ongoingRequests);

		this.uploadProgress$.next(0);

		const formData = new FormData();
		for (let file of Array.from(files)) formData.append('image', file);

		return this.http
			.post<OrderRxFileUploadApiResult[]>(
				`${endpoints.order.base}${endpoints.order.rxPass}`,
				formData,
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
						'Ocp-Apim-Subscription-Key': endpoints.order.ocpApimSubscriptionKey,
						Authorization: `Bearer ${localStorage.getItem('id-token-zid')}`,
					},
					responseType: 'json',
				}
			)
			.pipe(
				map((response) => {
					return response.map((r) => transformFileUploadApiResponse(r));
				}),
				tap({
					next: (response) => {
						return response;
					},
					error: (err) => {
						const backendError = logError(
							this.logger,
							'API Error: Cannot upload image',
							LogCat.order,
							false,
							true
						);
						this.backendFailure$.next(backendError);
					},
				}),
				finalize(() => {
					this.endRequest(val.id);
					this.uploadProgress$.next(100);
				})
			);
	}

	getImageData(fileId: string): Observable<ImageInformation> {
		const val = startRequest('getImageData');
		const ongoingRequests = this.ongoingRequests$.getValue();
		ongoingRequests.push(val);
		this.ongoingRequests$.next(ongoingRequests);

		return this.http
			.get<OrderRxPassImageApiResult>(
				`${endpoints.order.base}${endpoints.order.rxPass}/${fileId}`,
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
						'Ocp-Apim-Subscription-Key': endpoints.order.ocpApimSubscriptionKey,
						Authorization: `Bearer ${localStorage.getItem('id-token-zid')}`,
					},
				}
			)
			.pipe(
				map((response) => {
					const info: ImageInformation = {
						data: response.imageData,
						extension: response.imageFileType.replace('image/', ''),
						fileId,
						mimeType: response.imageFileType,
						name: response.rxPassFileName,
					};

					return info;
				}),
				tap({
					next: (response) => {
						return response;
					},
					error: (err) => {
						const backendError = logError(
							this.logger,
							'API Error: Cannot get current image',
							LogCat.order,
							false,
							true
						);
						this.backendFailure$.next(backendError);
					},
				}),
				finalize(() => {
					this.endRequest(val.id);
				})
			);
	}

	private endRequest(uuid: string) {
		const vals = this.ongoingRequests$.getValue();
		const index = vals.findIndex((x) => x.id === uuid);
		if (index > -1) {
			vals.splice(index, 1);
			this.ongoingRequests$.next(vals);
		}
	}
}
