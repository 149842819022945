import { Data, Route } from '@angular/router';
import { environment } from 'src/environments/environment';

export enum AppColors {
	'error' = 0,
	'warning',
	'success',
	'info',
	'grey',
	'white',
}

export type RoutesExtended = RouteExtended[];

export interface RouteExtended extends Route {
	data?: DataExtended;
}

export interface DataExtended extends Data {
	animationHierarchy?: number;
	title?: string;
	icon?: string;
	showInMenu?: boolean;
	cache?: boolean;
}

export interface ImageInformation {
	data: string;
	mimeType: string;
	extension: string;
	fileId: string;
	name: string;
}

export const App = {
	Name1: 'Developer',
	Name2: 'Order Portal',
	ZeissLogo: '/assets/images/app-logo/zeiss-logo.svg',
	VisionLogo: '/assets/images/app-logo/vision-logo.svg',
	AppEntered: new Date(),
	LocalStorage: {
		Prefix: `ATHENA${
			environment.appMeta.environment !== 'PROD' ? '_' + environment.appMeta.environment : ''
		}_`,
		Settings: 'SETTINGS',
		Logs: 'LOGS',
	},
	RouteAnimationDuration: 200,
};
